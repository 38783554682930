import dayjs from 'dayjs';
import { Campaigns } from '../../enums/campaigns';
import configData from '../../config/config.json';

export function setCampaignTemp(campaign: string | null, sourceRef: string | null) {
    let campaignName = campaign;
    let sourceRefName = sourceRef;

    if (!campaignName && !sourceRefName && dayjs().isAfter(configData.MANDS2025_START) && dayjs().isBefore(configData.MANDS2025_END)) {
        campaignName = Campaigns.mands2025;
        sourceRefName = Campaigns.mands2025;
    }

    return [campaignName, sourceRefName];
}
