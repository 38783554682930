import { Modal, H2, Text, theme } from '@digitalportal-ui/core';
import { Box, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { CardContainer } from './styles';
import { InfoLink } from '../../atoms/Link/Link';

export type CampaignCardProps = {
  header: string;
  subHeader: string;
  bodyText: string | React.ReactNode | React.ReactNode[];
  termsAndConditions: string | React.ReactNode | React.ReactNode[];
  image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

function CampaignCard({ header, subHeader, bodyText, termsAndConditions, image }: CampaignCardProps): React.JSX.Element {
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <CardContainer>
      <Box sx={{ width: { md: '60%' } }} flexDirection="column" alignSelf="center">
        <H2 data-testid="campaign-card-header" mb={2}>{header}</H2>
        <Text data-testid="campaign-card-sub-header" mb={1} semibold>{subHeader}</Text>
        <Text data-testid="campaign-card-body-text" mb={2}>{bodyText}</Text>
        <InfoLink
          testId="campaign-card-conditions-link"
          link="See terms and conditions"
          hideInfoLinkIcon
          onClickHandler={() => { setModalOpen(true); }}
        />
      </Box>
      <Box data-testid="campaign-card-image" sx={{ width: { md: '40%' } }} textAlign="center">
        {React.createElement(image, { style: { width: mobileBreakpoint ? '232px' : '280px', height: '100%' } })}
      </Box>
      <Modal
        variant="info"
        title="Terms and Conditions"
        open={modalOpen}
        onClose={() => { setModalOpen(false); }}
        dataTestid="tooltip-modal"
        showCloseFab
      >
        <Box sx={{ whiteSpace: 'pre-wrap' }}>{termsAndConditions}</Box>
      </Modal>
    </CardContainer>
  );
}
export default CampaignCard;
